import { useState } from "react";

import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import BackdropLoading from "components/elements/BackdropLoading";
import { useAPICreateCustomer, useAPIUpdateCustomer } from "hooks/useAPI";
import { enqueueSnackbar } from "notistack";
import { ICustomerInput } from "types";
import { CustomerType } from "types/enums";

interface IProps extends Partial<ICustomerInput> {
  handleClose: () => void;
  refetch: () => void;
  type: CustomerType;
}

const AddModal = ({ handleClose, refetch, id, type, ...props }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name ?? "");
  const [address, setAddress] = useState<string>(props.address ?? "");
  const [city, setCity] = useState<string>(props.city ?? "");
  const [postalCode, setPostalCode] = useState<string>(props.postalCode ?? "");
  const [country, setCountry] = useState<string>(props.country ?? "");
  const [ico, setIco] = useState<string>(props.ico ?? "");
  const [dic, setDic] = useState<string>(props.dic ?? "");
  const [email, setEmail] = useState<string>(props.email ?? "");
  const [phone, setPhone] = useState<string>(props.phone ?? "");
  const [note, setNote] = useState<string>(props.note ?? "");
  const [region, setRegion] = useState<string>(props.region ?? "");

  const [createCustomer] = useAPICreateCustomer();
  const [updateCustomer] = useAPIUpdateCustomer();

  const handleCreate = async () => {
    if (!name || !address || !city || !postalCode || !country) return;
    setLoading(true);
    try {
      await createCustomer({
        variables: {
          createCustomerInput: {
            name,
            address,
            city,
            postalCode,
            country,
            ico,
            dic,
            email,
            phone,
            note,
            type,
            region,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Zákazník bol úspešne vytvorený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Zakazníka sa nepodarilo vytvoriť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!name || !address || !city || !postalCode || !country) return;
    setLoading(true);
    try {
      await updateCustomer({
        variables: {
          updateCustomerInput: {
            id,
            name,
            address,
            city,
            postalCode,
            country,
            ico,
            dic,
            email,
            phone,
            note,
            type,
            region,
          },
        },
      });
      handleClose();
      refetch();
      enqueueSnackbar("Zákazník bol úspešne upravený", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Zákazníka sa nepodarilo upraviť", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const isPossibleToSubmit = () =>
    name.length > 0
    && address.length > 0
    && city.length > 0
    && postalCode.length > 0
    && country.length > 0;

  const handleSubmit = () => (id ? handleUpdate() : handleCreate());

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>{id ? "Upraviť" : "Pridať"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={1}>
          <TextField
            fullWidth
            label="Názov spoločnosti"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField fullWidth label="Adresa" required value={address} onChange={(e) => setAddress(e.target.value)} />
          <Stack direction={"row"} spacing={1}>
            <TextField
              sx={{ maxWidth: "35%" }}
              label="PSČ"
              required
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
            <TextField fullWidth label="Mesto" required value={city} onChange={(e) => setCity(e.target.value)} />
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <TextField fullWidth label="Krajina" required value={country} onChange={(e) => setCountry(e.target.value)} />
            <TextField fullWidth label="Región" value={region} onChange={(e) => setRegion(e.target.value)} />
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <TextField fullWidth label="IČO" value={ico} onChange={(e) => setIco(e.target.value)} />
            <TextField fullWidth label="DIČ" value={dic} onChange={(e) => setDic(e.target.value)} />
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField fullWidth label="Telefón" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Stack>
          <TextField
            fullWidth
            label="Poznámka"
            value={note}
            multiline
            rows={3}
            onChange={(e) => setNote(e.target.value)}
          />
        </Stack>
        <BackdropLoading loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Zrušiť
        </Button>
        <Button variant="contained" size="small" disabled={!isPossibleToSubmit()} onClick={handleSubmit}>
          {id ? "Upraviť" : "Vytvoriť"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
