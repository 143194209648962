import { gql, useMutation, useQuery } from "@apollo/client";
import {
  IArticle,
  IArticleInput,
  ICategory,
  ICategoryInput,
  IConstant,
  ICurrency,
  ICurrencyInput,
  ICustomer,
  ICustomerInput,
  ICustomersWithAnnualInspection,
  IDashboard,
  IDocument,
  IDocumentInput,
  IDocumentUpdateInput,
  ISubcategory,
  ISubcategoryByCategoryResult,
  ISubcategoryInput,
  ISubcategoryUpdate,
  IUnit,
  IUnitInput,
  IUserData,
  IUserUpdateInput,
  IWarehouse,
  IWarehouseInput,
  LoginInput,
  LoginResult,
} from "types";
import { CustomerType } from "types/enums";

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(loginInput: $input) {
      access_token
      username
      email
      name
      surname
      id
      role
    }
  }
`;
export function useAPILogin() {
  return useMutation<{ login: LoginResult }, { input: LoginInput }>(LOGIN);
}

const USER_INFO = gql`
  mutation UserInfo {
    userInfo {
      username
      email
      name
      surname
      id
      role
    }
  }
`;

export function useAPIUserInfo() {
  return useMutation<{ userInfo: IUserData }>(USER_INFO);
}

const WAREHOUSES = gql`
  query warehouses {
    warehouses {
      description
      id
      name
    }
  }
`;

const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($input: UpdateUserInput!) {
    updateUserInfo(updateUserInput: $input) {
      username
      email
      name
      surname
      id
    }
  }
`;

export function useAPIUpdateUserInfo() {
  return useMutation<{ updateUserInfo: IUserData }, { input: IUserUpdateInput }>(UPDATE_USER_INFO);
}

export function useAPIWarehouses() {
  return useQuery<{ warehouses: IWarehouse[] }>(WAREHOUSES);
}

const REMOVE_WAREHOUSE = gql`
  mutation removeWarehouse($removeWarehouseId: Int!) {
    removeWarehouse(id: $removeWarehouseId) {
      id
    }
  }
`;

export function useAPIRemoveWarehouse() {
  return useMutation<{ removeWarehouse: { id: number } }, { removeWarehouseId: number }>(REMOVE_WAREHOUSE);
}

const CREATE_WAREHOUSE = gql`
  mutation createWarehouse($createWarehouseInput: CreateWarehouseInput!) {
    createWarehouse(createWarehouseInput: $createWarehouseInput) {
      id
    }
  }
`;

export function useAPICreateWarehouse() {
  return useMutation<{ createWarehouse: { id: number } }, { createWarehouseInput: IWarehouseInput }>(CREATE_WAREHOUSE);
}

const UPDATE_WAREHOUSE = gql`
  mutation updateWarehouse($updateWarehouseInput: UpdateWarehouseInput!) {
    updateWarehouse(updateWarehouseInput: $updateWarehouseInput) {
      id
    }
  }
`;

export function useAPIUpdateWarehouse() {
  return useMutation<{ updateWarehouse: { id: number } }, { updateWarehouseInput: IWarehouseInput }>(UPDATE_WAREHOUSE);
}

const WAREHOUSE = gql`
  query warehouse($warehouseId: Int!) {
    warehouse(id: $warehouseId) {
      description
      id
      name
    }
  }
`;

export function useAPIWarehouse(id: number) {
  return useQuery<{ warehouse: IWarehouse }>(WAREHOUSE, { variables: { warehouseId: id } });
}

const ARTICLES = gql`
  query articles {
    articles {
      category {
        name
      }
      categoryId
      currency {
        name
        description
      }
      currencyId
      id
      manufacturer
      name
      note
      price
      priceVat
      subcategory {
        name
      }
      subcategoryId
      unit {
        name
      }
      unitId
      quantity
    }
  }
`;

export function useAPIArticles() {
  return useQuery<{ articles: IArticle[] }>(ARTICLES, { fetchPolicy: "no-cache" });
}

const ARTICLES_LOW_STOCK = gql`
  query articlesLowStock {
    articlesLowStock {
      category {
        name
      }
      currency {
        name
      }
      id
      manufacturer
      minStock
      name
      note
      price
      priceVat
      subcategory {
        name
      }
      unit {
        name
      }
      quantity
    }
  }
`;

export function useAPIArticlesLowStock() {
  return useQuery<{ articlesLowStock: IArticle[] }>(ARTICLES_LOW_STOCK, { fetchPolicy: "no-cache" });
}

const ARTICLES_BY_WAREHOUSE = gql`
  query articlesByWarehouse($warehouseId: Int!) {
    articlesByWarehouse(warehouseId: $warehouseId) {
      category {
        name
      }
      categoryId
      currency {
        name
        description
      }
      currencyId
      id
      manufacturer
      name
      note
      price
      priceVat
      subcategory {
        name
      }
      subcategoryId
      unit {
        name
      }
      unitId
      quantity
      minStock
    }
  }
`;

export function useAPIArticlesByWarehouse(warehouseId?: number) {
  return useQuery<{ articlesByWarehouse: IArticle[] }>(ARTICLES_BY_WAREHOUSE, {
    variables: { warehouseId },
    skip: !warehouseId,
    fetchPolicy: "no-cache",
  });
}

const GET_ARTICLES_BY_WAREHOUSE = gql`
  mutation articlesByWarehouse($warehouseId: Int!) {
    articlesByWarehouse(warehouseId: $warehouseId) {
      category {
        name
      }
      categoryId
      currency {
        name
        description
      }
      currencyId
      id
      manufacturer
      name
      note
      price
      priceVat
      subcategory {
        name
      }
      subcategoryId
      unit {
        name
      }
      unitId
      quantity
      minStock
    }
  }
`;

export function useAPIGetArticlesByWarehouse() {
  return useMutation<{ articlesByWarehouse: IArticle[] }, { warehouseId: number }>(GET_ARTICLES_BY_WAREHOUSE);
}

const CATEGORIES = gql`
  query categories {
    categories {
      description
      id
      name
    }
  }
`;

export function useAPICategories() {
  return useQuery<{ categories: ICategory[] }>(CATEGORIES);
}

const SUBCATEGORIES = gql`
  query subcategories {
    subcategories {
      categoryId
      description
      id
      name
      category {
        name
      }
    }
  }
`;

export function useAPISubcategories() {
  return useQuery<{ subcategories: ISubcategory[] }>(SUBCATEGORIES);
}

const SUBCATEGORIES_BY_CATEGORY = gql`
  query subcategoriesByCategory($categoryId: Int!) {
    subcategoriesByCategory(categoryId: $categoryId) {
      description
      id
      name
    }
  }
`;

export function useAPISubcategoriesByCategory(id?: number | null) {
  return useQuery<{ subcategoriesByCategory: ISubcategoryByCategoryResult[] }>(SUBCATEGORIES_BY_CATEGORY, {
    variables: { categoryId: id },
    skip: !id,
  });
}

const UNITS = gql`
  query units {
    units {
      description
      id
      name
    }
  }
`;

export function useAPIUnits() {
  return useQuery<{ units: IUnit[] }>(UNITS);
}

const CURRENCIES = gql`
  query currencies {
    currencies {
      description
      id
      name
    }
  }
`;

export function useAPICurrencies() {
  return useQuery<{ currencies: ICurrency[] }>(CURRENCIES);
}

const CREATE_CATEGORY = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(createCategoryInput: $input) {
      id
    }
  }
`;

export function useAPICreateCategory() {
  return useMutation<{ createCategory: { id: number } }, { input: ICategoryInput }>(CREATE_CATEGORY);
}

const REMOVE_CATEGORY = gql`
  mutation removeCategory($removeCategoryId: Int!) {
    removeCategory(id: $removeCategoryId) {
      id
    }
  }
`;

export function useAPIRemoveCategory() {
  return useMutation<{ removeCategory: { id: number } }, { removeCategoryId: number }>(REMOVE_CATEGORY);
}

const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(updateCategoryInput: $input) {
      id
    }
  }
`;

export function useAPIUpdateCategory() {
  return useMutation<{ updateCategory: { id: number } }, { input: ICategory }>(UPDATE_CATEGORY);
}

const CREATE_UNIT = gql`
  mutation createUnit($input: CreateUnitInput!) {
    createUnit(createUnitInput: $input) {
      id
    }
  }
`;

export function useAPICreateUnit() {
  return useMutation<{ createUnit: { id: number } }, { input: IUnitInput }>(CREATE_UNIT);
}

const REMOVE_UNIT = gql`
  mutation removeUnit($removeUnitId: Int!) {
    removeUnit(id: $removeUnitId) {
      id
    }
  }
`;

export function useAPIRemoveUnit() {
  return useMutation<{ removeUnit: { id: number } }, { removeUnitId: number }>(REMOVE_UNIT);
}

const UPDATE_UNIT = gql`
  mutation updateUnit($input: UpdateUnitInput!) {
    updateUnit(updateUnitInput: $input) {
      id
    }
  }
`;

export function useAPIUpdateUnit() {
  return useMutation<{ updateUnit: { id: number } }, { input: IUnit }>(UPDATE_UNIT);
}

const CREATE_CURRENCY = gql`
  mutation createCurrency($input: CreateCurrencyInput!) {
    createCurrency(createCurrencyInput: $input) {
      id
    }
  }
`;

export function useAPICreateCurrency() {
  return useMutation<{ createCurrency: { id: number } }, { input: ICurrencyInput }>(CREATE_CURRENCY);
}

const REMOVE_CURRENCY = gql`
  mutation removeCurrency($removeCurrencyId: Int!) {
    removeCurrency(id: $removeCurrencyId) {
      id
    }
  }
`;

export function useAPIRemoveCurrency() {
  return useMutation<{ removeCurrency: { id: number } }, { removeCurrencyId: number }>(REMOVE_CURRENCY);
}

const UPDATE_CURRENCY = gql`
  mutation updateCurrency($input: UpdateCurrencyInput!) {
    updateCurrency(updateCurrencyInput: $input) {
      id
    }
  }
`;

export function useAPIUpdateCurrency() {
  return useMutation<{ updateCurrency: { id: number } }, { input: ICurrency }>(UPDATE_CURRENCY);
}

const CREATE_SUBCATEGORY = gql`
  mutation createSubcategory($input: CreateSubcategoryInput!) {
    createSubcategory(createSubcategoryInput: $input) {
      id
    }
  }
`;

export function useAPICreateSubcategory() {
  return useMutation<{ createSubcategory: { id: number } }, { input: ISubcategoryInput }>(CREATE_SUBCATEGORY);
}

const REMOVE_SUBCATEGORY = gql`
  mutation removeSubcategory($removeSubcategoryId: Int!) {
    removeSubcategory(id: $removeSubcategoryId) {
      id
    }
  }
`;

export function useAPIRemoveSubcategory() {
  return useMutation<{ removeSubcategory: { id: number } }, { removeSubcategoryId: number }>(REMOVE_SUBCATEGORY);
}

const UPDATE_SUBCATEGORY = gql`
  mutation updateSubcategory($input: UpdateSubcategoryInput!) {
    updateSubcategory(updateSubcategoryInput: $input) {
      id
    }
  }
`;

export function useAPIUpdateSubcategory() {
  return useMutation<{ updateSubcategory: { id: number } }, { input: ISubcategoryUpdate }>(UPDATE_SUBCATEGORY);
}

const REMOVE_ARTICLE = gql`
  mutation removeArticle($removeArticleId: Int!) {
    removeArticle(id: $removeArticleId) {
      id
    }
  }
`;

export function useAPIRemoveArticle() {
  return useMutation<{ removeArticle: { id: number } }, { removeArticleId: number }>(REMOVE_ARTICLE);
}

const REMOVE_ARTICLES = gql`
  mutation removeArticles($removeArticleIds: [Int!]!) {
    removeArticles(ids: $removeArticleIds) {
      deletedIds
      nonDeletedIds
    }
  }
`;

export function useAPIRemoveArticles() {
  return useMutation<{ removeArticles: { deletedIds: number[]; nonDeletedIds: number[] } }, { removeArticleIds: number[] }>(
    REMOVE_ARTICLES
  );
}

const CREATE_ARTICLE = gql`
  mutation createArticle($createArticleInput: CreateArticleInput!) {
    createArticle(createArticleInput: $createArticleInput) {
      id
    }
  }
`;

export function useAPICreateArticle() {
  return useMutation<{ createArticle: { id: number } }, { createArticleInput: IArticleInput }>(CREATE_ARTICLE);
}

const UPDATE_ARTICLE = gql`
  mutation createArticle($updateArticleInput: UpdateArticleInput!) {
    updateArticle(updateArticleInput: $updateArticleInput) {
      id
    }
  }
`;

export function useAPIUpdateArticle() {
  return useMutation<{ updateArticle: { id: number } }, { updateArticleInput: IArticleInput }>(UPDATE_ARTICLE);
}

const DOCUMENTS = gql`
  query warehouseDocuments {
    warehouseDocuments {
      createdAt
      updatedAt
      currencyId
      sourceWarehouseId
      currencyId
      currency {
        name
        description
      }
      customerId
      customer {
        name
        note
      }
      id
      number
      price
      type
      creator
      note
      warehouseId
      warehouse {
        name
      }
      sourceWarehouseId
      sourceWarehouse {
        name
      }
      items {
        articleId
        price
        quantity
      }
      comment
      user {
        id
        name
        surname
        email
      }
    }
  }
`;

export function useAPIDocuments() {
  return useQuery<{ warehouseDocuments: IDocument[] }>(DOCUMENTS, { fetchPolicy: "no-cache" });
}

const CUSTOMERS = gql`
  query customers {
    customers {
      address
      city
      country
      createdAt
      dic
      email
      ico
      id
      name
      note
      phone
      postalCode
      updatedAt
    }
  }
`;

export function useAPICustomers() {
  return useQuery<{ customers: ICustomer[] }>(CUSTOMERS);
}

const CUSTOMERS_BY_TYPE = gql`
  query customersByType($type: String!) {
    customersByType(type: $type) {
      address
      city
      country
      createdAt
      dic
      email
      ico
      id
      name
      note
      phone
      postalCode
      updatedAt
      region
    }
  }
`;

export function useAPICustomersByType(type: CustomerType) {
  return useQuery<{ customersByType: ICustomer[] }>(CUSTOMERS_BY_TYPE, {
    variables: { type: type },
    fetchPolicy: "no-cache",
  });
}

const REMOVE_CUSTOMER = gql`
  mutation removeCustomer($removeCustomerId: Int!) {
    removeCustomer(id: $removeCustomerId) {
      id
    }
  }
`;

export function useAPIRemoveCustomer() {
  return useMutation<{ removeCustomer: { id: number } }, { removeCustomerId: number }>(REMOVE_CUSTOMER);
}

const REMOVE_CUSTOMERS = gql`
  mutation removeCustomers($removeCustomerIds: [Int!]!) {
    removeCustomers(ids: $removeCustomerIds) {
      deletedIds
      nonDeletedIds
    }
  }
`;

export function useAPIRemoveCustomers() {
  return useMutation<{ removeCustomers: { deletedIds: number[]; nonDeletedIds: number[] } }, { removeCustomerIds: number[] }>(
    REMOVE_CUSTOMERS
  );
}

const CREATE_CUSTOMER = gql`
  mutation createCustomer($createCustomerInput: CreateCustomerInput!) {
    createCustomer(createCustomerInput: $createCustomerInput) {
      id
    }
  }
`;

export function useAPICreateCustomer() {
  return useMutation<{ createCustomer: { id: number } }, { createCustomerInput: ICustomerInput }>(CREATE_CUSTOMER);
}

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($updateCustomerInput: UpdateCustomerInput!) {
    updateCustomer(updateCustomerInput: $updateCustomerInput) {
      id
    }
  }
`;

export function useAPIUpdateCustomer() {
  return useMutation<{ updateCustomer: { id: number } }, { updateCustomerInput: ICustomerInput }>(UPDATE_CUSTOMER);
}

const CREATE_DOCUMENT = gql`
  mutation CreateWarehouseDocument($createWarehouseDocumentInput: CreateWarehouseDocumentInput!) {
    createWarehouseDocument(createWarehouseDocumentInput: $createWarehouseDocumentInput) {
      id
    }
  }
`;

export function useAPICreateDocument() {
  return useMutation<{ createWarehouseDocument: { id: number } }, { createWarehouseDocumentInput: IDocumentInput }>(
    CREATE_DOCUMENT
  );
}

const UPDATE_DOCUMENT = gql`
  mutation UpdateWarehouseDocument($updateWarehouseDocumentInput: UpdateWarehouseDocumentInput!) {
    updateWarehouseDocument(updateWarehouseDocumentInput: $updateWarehouseDocumentInput) {
      id
    }
  }
`;

export function useAPIUpdateDocument() {
  return useMutation<{ updateWarehouseDocument: { id: number } }, { updateWarehouseDocumentInput: IDocumentUpdateInput }>(
    UPDATE_DOCUMENT
  );
}

const DASHBOARD = gql`
  query dashboard {
    dashboard {
      customerTotal
      documentTotal
      productTotal
      warehouseTotal
      lowStock {
        id
        name
        price
        priceVat
        currencyId
        categoryId
        subcategoryId
        quantity
        note
        manufacturer
        unitId
        minStock
      }
    }
  }
`;

export function useAPIDashboard() {
  return useQuery<{ dashboard: IDashboard }>(DASHBOARD, { fetchPolicy: "no-cache" });
}

const CONSTANTS_BY_CODE = gql`
  query constantByCode($code: String!) {
    constantByCode(code: $code) {
      code
      value
      id
    }
  }
`;

export function useAPIConstantsByCode(code: string) {
  return useQuery<{ constantByCode: IConstant }>(CONSTANTS_BY_CODE, { variables: { code }, fetchPolicy: "no-cache" });
}

const UPDATE_CONSTANT = gql`
  mutation updateConstant($input: UpdateConstantInput!) {
    updateConstant(updateConstantInput: $input) {
      id
    }
  }
`;

export function useAPIUpdateConstant() {
  return useMutation<{ updateConstant: { id: number } }, { input: IConstant }>(UPDATE_CONSTANT);
}

const UPDATE_MIN_STOCK = gql`
  mutation UpdateMinStock($articleId: Int!, $minStock: Int!, $warehouseId: Int!) {
    updateMinStock(articleId: $articleId, minStock: $minStock, warehouseId: $warehouseId) {
      minStock
    }
  }
`;

export function useAPIUpdateMinStock() {
  return useMutation<
    { updateMinStock: { minStock: number } },
    { articleId: number; minStock: number; warehouseId: number }
  >(UPDATE_MIN_STOCK);
}

const DOCUMENTS_BY_CUSTOMER = gql`
  query warehouseDocumentsByCustomerId($customerId: Int!) {
    warehouseDocumentsByCustomerId(customerId: $customerId) {
      createdAt
      updatedAt
      currencyId
      sourceWarehouseId
      currencyId
      currency {
        name
      }
      customerId
      customer {
        name
      }
      id
      number
      price
      type
      creator
      note
      warehouseId
      warehouse {
        name
      }
      sourceWarehouseId
      sourceWarehouse {
        name
      }
      items {
        articleId
        price
        quantity
      }
      user {
        id
        name
        surname
        email
      }
    }
  }
`;

export function useAPIDocumentsByCustomer(customerId: number) {
  return useQuery<{ warehouseDocumentsByCustomerId: IDocument[] }>(DOCUMENTS_BY_CUSTOMER, { variables: { customerId } });
}

const DOCUMENTS_BY_ARTICLE = gql`
  query warehouseDocumentsByArticleId($articleId: Int!) {
    warehouseDocumentsByArticleId(articleId: $articleId) {
      createdAt
      updatedAt
      currencyId
      sourceWarehouseId
      currencyId
      currency {
        name
      }
      customerId
      customer {
        name
      }
      id
      number
      price
      type
      creator
      note
      warehouseId
      warehouse {
        name
      }
      sourceWarehouseId
      sourceWarehouse {
        name
      }
      items {
        articleId
        price
        quantity
      }
      user {
        id
        name
        surname
        email
      }
    }
  }
`;

export function useAPIDocumentsByArticle(articleId: number) {
  return useQuery<{ warehouseDocumentsByArticleId: IDocument[] }>(DOCUMENTS_BY_ARTICLE, { variables: { articleId } });
}

const REMOVE_STOCK = gql`
  mutation removeStock($articleId: Int!, $warehouseId: Int!) {
    removeStock(articleId: $articleId, warehouseId: $warehouseId) {
      warehouseId
      articleId
    }
  }
`;

export function useAPIRemoveStock() {
  return useMutation<{ removeStock: { warehouseId: number, articleId: number } }, { articleId: number; warehouseId: number }>(REMOVE_STOCK);
}

const CUSTOMERS_WITH_ANNUAL_INSPECTION = gql`
  query customersWithAnnualInspection($year: Int!) {
    customersWithAnnualInspection(year: $year) {
      id
      name
      address
      city
      note
      country
      createdAt
      dic
      email
      ico
      phone
      postalCode
      updatedAt
      region
      warehouseDocuments {
        comment
        createdAt
        creator
        currency {
          name
          id
          description
        }
        currencyId
        customerId
        id
        items {
          id
          articleId
          quantity
          price
        }
        note
        number
        price
        sourceWarehouse {
          name
        }
        sourceWarehouseId
        type
        updatedAt
        user {
          id
          name
          surname
          email
        }
        userId
        warehouse {
          name
        }
        warehouseId
      }
    }
  }
`;

export function useAPICustomersWithAnnualInspection(year: number) {
  return useQuery<{ customersWithAnnualInspection: ICustomersWithAnnualInspection[] }>(CUSTOMERS_WITH_ANNUAL_INSPECTION,
    {
      variables: { year },
      fetchPolicy: "no-cache",
    }
  );
}